$(document).ready(() => {
    /* navigation menu  */
    const $navigation = $('.iqitmegamenu-wrapper');
    /**
     * Mobile
     */
    /*$navigation.find('.dropdown .a-niveau1').click(function(event) {
      if ($(window).width() < 1199) {
        event.preventDefault();
      }
      $(this).next(".dropdown-menu").addClass("show");
    });
    $navigation.find('.btn-return').click(function() {
       $(this).closest(".dropdown-menu").removeClass("show");
    });
    $('.navbar-toggler').click(function() {
        $("html, body").toggleClass('fixed-body');
        $navigation.find('.sub-menu').removeClass("show");
    });*/

    $(".cbp-has-submeu").hover(function() {
      $("body").addClass('overlay');
    }, function() {
      $("body").removeClass('overlay');
    });

    $('.navbar-toggler, .btn-nav-close').click(function() {
      $navigation.toggleClass('open');
      $("html, body").toggleClass('fixed-body');
    });
    
    $navigation.find('.cbp-hrmenu-tab:not(.cbp-pulled-right) > a').each(function() {
      $(this).click(function() {
        $(this).closest('.cbp-hrmenu-tab').toggleClass('show');
        return false;
      });
    });

    $navigation.find('.cbp-hrsub-tabs-names li[class*="innertab"] a').each(function() {
      $(this).click(function() {
        var $target = $(this).data('target');

        $($target).addClass('show');

        return false;
      });
    });

    $(".return-tab-nav").click(function() {
      $(this).closest('.cbp-tab-pane').removeClass('show');
    });
});