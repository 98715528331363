$(document).ready(() => {
    $("body#product").each(function() {
        $(".product-accessories .owl-carousel").owlCarousel({
            items : 4,
            margin : 30,
            dots : false,
            responsive : {
                0 : {
                  items : 1
                },
                460 : {
                  items : 2
                },
                992 : {
                  items : 3
                },
                1199 : {
                    items : 4
                }
            }
        });

        $('.btn-pack').click(function() {
          var $accordionPack = $('#accordionPack');
          var position = $accordionPack.offset().top;

          $('body, html').animate({'scrollTop' : position}, 500);
        });

        $(".product-pack .owl-carousel").owlCarousel({
            items : 3,
            margin : 30,
            dots : false,
            responsive : {
                0 : {
                  items : 1
                },
                460 : {
                  items : 2
                },
                992 : {
                  items : 2
                },
                1199 : {
                    items : 3
                }
            }
        });

        if ($('.ps_categoryproducts').length > 0) {
          $('.ps_categoryproducts .products').addClass('owl-carousel owl-theme');
          $('.ps_categoryproducts .products').owlCarousel({
            items: 3,
            dots: false,
            loop: false,
            slideBy: 'page',
            margin: 30,
            navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
            autoplay: false,
            responsive: {
              0 : {
                items : 1.25,
                loop : true
              },
              768 : {
                items: 2
              },
              992 : {
                items: 3,
                dots: true
              }
            }
        });
      }
    });
});