$(document).ready(() => {
    $("body#cart").each(function() {
       if ($('#csoc-container').length > 0) {
          $('#csoc-container .owl-carousel').owlCarousel({
            items: 3,
            dots: false,
            loop: false,
            slideBy: 'page',
            margin: 30,
            navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
            autoplay: false,
            responsive: {
              0 : {
                items : 1.25,
                loop : true
              },
              768 : {
                items: 2
              },
              992 : {
                items: 3,
                dots: true
              }
            }
        });
      }
    });
});