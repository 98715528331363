$(document).ready(() => {

    $("body#index").each(function() {
      $(".categories-slider").owlCarousel({
        items : 3,
        dots : false,
        loop : false,
        slideBy : 'page',
        margin : 30,
        navText : ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
        autoplay : false,
        responsive : {
          0 : {
            items : 1.25,
            loop : true
          },
          768 : {
            items : 2
          },
          992 : {
            items : 3,
            dots : true
          }
        }
      });

    });

    $('.toggleThemeIndex').click(function() {
        var categoryId = $(this).data('id');
        $('.theme-content-list').hide();
        $('#theme-content-'+categoryId).show();
        $('#theme-content-image-'+categoryId).show();
    });
});