$(document).ready(() => {
    $("body#order-confirmation").each(function() {
      $(".products").owlCarousel({
        items: 4,
        dots: false,
        loop: false,
        slideBy: 'page',
        margin: 0,
        //nav: true,
        navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
        autoplay: false,
        responsive: {
          0 : {
            items: 1
          },
          768 : {
            items: 2
          },
          992 : {
            items: 4,
            dots: true
          }
        }
      });
    });

    if ($(".featured-products").length) {
      $(".featured-products .products").owlCarousel({
        items: 4,
        dots: false,
        loop: false,
        slideBy: 'page',
        margin: 30,
        //nav: true,
        navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
        autoplay: false,
        responsive: {
          0 : {
            items: 1
          },
          768 : {
            items: 2
          },
          992 : {
            items: 4,
            dots: true
          }
        }
      });
    }
});