$(document).ready(() => {
    if ($(".themes--main-products")) {
      $(".themes--main-products .owl-carousel").owlCarousel({
        items: 3,
        dots: false,
        loop: false,
        slideBy: 'page',
        margin: 30,
        //nav: true,
        navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
        autoplay: false,
        responsive: {
          0 : {
            items: 1
          },
          768 : {
            items: 2
          },
          992 : {
            items: 3,
            dots: true
          }
        }
      });
    }
    if ($(".themes--second-products")) {
      $(".themes--second-products .owl-carousel").owlCarousel({
        items: 3,
        dots: false,
        loop: false,
        slideBy: 'page',
        margin: 30,
        //nav: true,
        navText: ['<i class="is is-arrow-left"></i>','<i class="is is-arrow-right"></i>'],
        autoplay: false,
        responsive: {
          0 : {
            items: 1
          },
          768 : {
            items: 2
          },
          992 : {
            items: 3,
            dots: true
          }
        }
      });
    }
});  