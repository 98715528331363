//form-display-products

function displayProducts() {
    var $formDisplayProducts = $("#form-display-products");
    var inputChecked = $formDisplayProducts.find("input:checked").val();
    var $containerProductList = $("#js-product-list .products");

    $containerProductList.attr({"data-display" : inputChecked});
}

$(document).ready(() => {

    $("body#category").each(function() {
        displayProducts();

        $("#form-display-products input").change(function() {
            displayProducts();
        });

        $("body").on('click', '#search_filters .title', function() {
            var $facet = $(this).closest(".facet");

            if ($facet.hasClass('open')) {
                $facet.removeClass("open");
            } else {
                $(".facet").removeClass('open');
                $facet.addClass("open");
            }
        });
        $("body").on('click', '#search_filters .show-panel-facets', function() {
            $("#panel-facets").toggleClass("open");
        });
        $("body").on('click', '#search_filters .btn-all-filters', function() {
            $("#search_filters .more-facet").toggleClass("unvisible-facet");
            $(this).toggleClass('active');
        });
    });
});