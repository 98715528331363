/* Global JS */

const WOW = require('wowjs');

function carouselMobile() {
  $carousel = $('.carousel-mobile');
  if ($(window).width() < 992) {
      if ($carousel.not('.owl-carousel')) {
          $carousel.addClass('owl-carousel owl-theme');
      }
      $carousel.owlCarousel({
          dots: false,
          items: 1,
          margin: 0,
          autoplay: true,
          nav: false
      });
  } else {
      $carousel.owlCarousel('destroy');
      $carousel.removeClass('owl-carousel owl-theme');
  }
}

function scrollTo() {
  $(".scrollTo").click(function() {
      var $this = $(this);
      var link = $this.attr("href");
      var position = $(link).offset().top;
      $("body, html").stop(true, true).animate({"scrollTop": position}, 500);
      return false;
  });
}

$(document).ready(() => {
  new WOW.WOW({live: false}).init();

  scrollTo();
  carouselMobile();
  
  $('[data-toggle="tooltip"]').tooltip();

  const selectPicker = (selector) => $(selector).selectpicker({
    mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent),
    width: '100%'
  });

  $(".btn-return-breadcrumb").on('click', function(){
    window.history.back();
  });

  selectPicker('select.select-custom');

  prestashop.on("updatedProduct", () => {
      selectPicker("select.select-custom");
  });

  $(".search-mobile, .close-search-mobile").click(function() {
    $("#search_widget").toggleClass("open");
  });
  
  $("#footer .title-footer").click(function() {
    $(this).toggleClass("open");
  });
});

$(window).resize(function() {
  carouselMobile();
});