;(function($) {
	function getCookie(name) {
		setPos = document.cookie.indexOf(name + '='), stopPos = document.cookie.indexOf(';', setPos);
		return !~setPos ? null : document.cookie.substring(
				setPos, ~stopPos ? stopPos : undefined).split('=')[1];
	}
	function setCookie(name, val, days, path) {
		var cookie = name + "=" +  escape(val) + "";
		if (days) {
			var date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			cookie += ";expires=" + date.toGMTString();
		}
		cookie += ";" + (path || "path=/");
		document.cookie = cookie;
	}

	$(document).ready(() => {
		var bandeauCookie = getCookie("bandeauCookie");

        if (!bandeauCookie) {
			$("#cookie-banner").show();
			$(".cookie-banner-close").click(function() {
				setCookie("bandeauCookie", 1, 0);
				$("#cookie-banner").fadeOut('fast');
			});
		} else {
			$("#cookie-banner").hide();
		}
	});
})(jQuery);